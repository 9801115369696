// Actions are payloads of informations that are used to modify the
// application's Redux state. They may also act as signals and information
// payloads to sagas when asynchronous operations need to occur. This app uses
// [Flux Standard Actions](https://github.com/acdlite/flux-standard-action) to
// make sure actions have a uniform schema.
//
// http://redux.js.org/docs/basics/Actions.html
import { createAction } from 'redux-actions';
import { upperSnakeCase } from './utils';
import camelCase from 'lodash/camelCase';

// The action type names for this app's Redux/Flux actions. Used to generate
// `ActionTypes` and `Actions` used throughout the app.
// actionName -> ActionType ('action name' -> 'ACTION_NAME')
// actionName -> ActionCreator ('action name' -> 'actionName')
let actionNames = normalize(
    // Data load resource actions
    ...loadAll(
        'questions',
        'inclusion breakdown data',
        'diversity breakdown data',
        'employee overview data',
        'inclusion metrics data',
        'inclusion metrics data bespoke',
        'inclusion metrics excel',
        'non overview metrics data',
        'non overview metrics data bespoke',
        'non overview metrics excel',
        'exec summary data',
        'email response',
        'painpoint data',
        'diversity composition data',
        'metric overview data',
        'focus area data',
        'inclusion score data',
        'company filters',
        'bespoke company filters',
        'targets',
        'recommendations',
        'action plans',
        'company information',
        'target options',
        'road map checklist',
        'inclusion tracker data',
        'diversity tracker data',
        'diversity breakdown by role data',
        'road map checklist',
        'free text',
        'profile',
        'all posts',
        'comments',
        'bookmark',
        'filtered posts',
        'all kpis',
        'filter map',
        'all solutions',
        'one pager',
        'communications tokens',
        'communications slack analytics',
        'communications github analytics',
        'core surveys',
        'all surveys',
        'iln members',
        'iln milestones',
        'iln initiatives',
        'iln investment regions',
        'iln aum breakdown',
        'iln news',
        'iln calendar',
        'dashboard list',
        'solution events',
        'solution tools',
        'solution trainings',
        'solution tools filters',
        'solution recommended insights',
        'solution recommended programs',
        'solution more recommended programs',
        'solution recommended create goal',
        'solution recommended goal options',
        'solution recommended client goals',
        'solution my goals',
        'solution my programs',
        'solution my solutions archive',
        'solution goals options',
        'solution goals insight data',
        'solution goals recommended programs',
        'solution goals reset',
        'solution all programs',
        'solution all programs v2',
        'solution all programs filters',
        'bespoke questions',
        'bespoke demographics',
        // New Programs API actions
        'all programs v2',
        'recommended insights',
        'goals options v2',
        'recommended programs',
        'more recommended programs',
        // New My Solutions API actions
        'solution my goals v2',
        'solution my programs v2',
        'solution my tools v2',
        'solution my solution filters v2',
        'solution my solutions archive v2',
        'solution goals options v2',
        'solution goals insight data v2',
        'solution my solutions archive v2',
    ),
    ...doAll(
        'submit response',
        'save email',
        'login',
        'registration',
        'self serve registration',
        'request verification code',
        'validate verification code',
        'fetch existing backup codes',
        'fetch new backup codes',
        'refresh token',
        'upload csv',
        'apply target',
        'signout',
        'create action plan',
        'inform purchase',
        'delete action plan',
        'delete target',
        'update road map checklist',
        'update profile',
        'upload profile image',
        'create post',
        'edit post',
        'delete post',
        'create comment',
        'edit comment',
        'delete comment',
        'like post',
        'like comment',
        'update bookmark',
        'update target',
        'update company solutions',
        'delete solution',
        'update budget',
        'create new solution',
        'create new company solution',
        'send onepager ping',
        'change password',
        'request reset password',
        'reset password',
        'update communications tokens',
        'delete communications tokens',
        'save survey info',
        'edit survey info',
        'save surveys',
        'edit surveys',
        'reset survey info',
        'refresh response count',
        'clear company information',
        'active dashboard',
        'solution goals create',
        'solution goals create error',
        'solution goal delete',
        'solution recommended goals create',
        'solution my solution update goal',
        'solution my solution update goal error',
        'solution my add program',
        'solution my remove company tool',
        'solution remove',
        'solution activate',
        'solution deactivate',
        'solution archive',
        'solution unarchive',
        'solution save tool',
        'solution save training',
        'solution remove saved tool',
        'solution remove saved training',
        'solution modify tool in row',
        'solution modify training in row',
        'initialize sso',
        'sso callback',
        'update company filters',
        // New Programs API
        'save program v2',
        'remove solution v2',
        'solution remove v2',
        'change solution status',
        // New Recommended API
        'save recommended program v2',
        'remove recommended solution v2',
        'change recommended solution status',
        'create goal',
        'delete goal',
        // New My Solution API actions
        'solution my solution update goal v2',
        'solution my solution update goal error v2',
        'solution goal delete v2',
        'solution activate v2',
        'solution deactivate v2',
        'solution archive v2',
        'solution remove saved tool v2',
        'my solution remove v2',
        'solution unarchive v2',

    ),
    'initialize app',
    'reload dashboard',
    'set survey completed',
    'mark solution selected',
    'unmark solution selected',
    'add target',
    'toggle targets is committed',
    'show message',
    'set global message',
    'reset global message',
    'reset backup codes',
    'reset mfa code requested',
    'set survey completed',
    'handle email change',
    'handle field change',
    'reset auth',
    'enable mfa',
    'request reset password fail',
    'reset password fail',
    'update survey questions',
    'update survey intro',
    'set selected survey',
    'set current selected survey page',
    'handle survey reset',
    'reset survey info',
    'handle language change',
    'show toast',
    'set global toast',
    'reset global toast',
    'reset tools',
    'reset trainings',
    'reset tool choices',
    'all programs page activate',
    'all programs page deactivate',
    'all programs page add program',
    'all programs page remove program',
    'recommended page activate',
    'recommended page deactivate',
    'recommended page add program',
    'recommended page remove program',
    'goals page activate',
    'goals page deactivate',
    'goals page add program',
    'goals page remove program',
    'my solution page activate',
    'my solution page deactivate',
    'my solution page archive',
    'my solution page remove program',
    'update solution archive count',
    'recommended page reset goal created',
    'reset recommended page',
    'end of tools',
    'end of trainings',
    'end of recommended programs',
    'end of goals page programs',
    // New my solutions page actions
    'my solution page archive v2',
    'reset programs and solutions',
);

// Create multiple load action types (get, loading and loading)
function loadAll (...names) {
    return [].concat(...names.map(load));
}

// Create multiple load action types (do, doing and done)
function doAll(...names) {
    return [].concat(...names.map(doit))
}

// Create load action type variants with do, doing and done prefixed
function doit (resourceName) {
    return [
        `do ${resourceName}`,
        `doing ${resourceName}`,
        `done ${resourceName}`,
        `failed ${resourceName}`
    ];
}

// Create load action type variants with get, loading and loading prefixed
function load (resourceName) {
    return [
        `get ${resourceName}`,
        `loading ${resourceName}`,
        `loaded ${resourceName}`,
        `failed loading ${resourceName}`
    ];
}

// Uper snake case names
function normalize (...names) {
    return names.map(upperSnakeCase);
}

// The app's redux action types. Each key is the same as the string action type.
// For each action name, the type is generated by upper snake-casing the phrase.
// assert.equal(ActionTypes.EAT_CAKE, 'EAT_CAKE');
export const ActionTypes = actionNames.reduce((actionTypes, actionName) => {
    actionTypes[actionName] = actionName;
    return actionTypes;
}, {});

// Action creators for the app (functions that return Redux/Flux actions).
// let action = Actions.wingardiumLeviosa('ferret');
// assert.deepEqual(action, { type: 'WINGARDIUM_LEVIOSA', payload: 'ferret' });
export const Actions = actionNames.reduce((actions, actionName) => {
    let actionCreatorName = camelCase(actionName);
    actions[actionCreatorName] = createAction(actionName, x => x, (x, y) => y);
    return actions;
}, {});
