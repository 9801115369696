/** @format */

import { delay } from 'redux-saga';
import { call, put } from "redux-saga/effects";
import { Actions, ActionTypes } from "../actions";
import {
  loadSolutionMyGoals,
  loadSolutionMyPrograms,
  loadSolutionMySolutionUpdateGoal,
  loadSolutionMyGoalsV2,
  loadMySavedProgramsV2,
  loadMyActiveProgramsV2,
  loadMyArchivedProgramsV2,
  removeSavedSolutionV2,
} from "./network";

// All data sagas to add to middleware.
export default [
  [ActionTypes.GET_SOLUTION_MY_GOALS, getSolutionMyGoals],
  [ActionTypes.GET_SOLUTION_MY_PROGRAMS, getSolutionMyPrograms],
  [ActionTypes.DO_SOLUTION_MY_SOLUTION_UPDATE_GOAL, doSolutionMySolutionUpdateGoal],
  [ActionTypes.GET_SOLUTION_MY_GOALS_V2, getSolutionMyGoalsV2],
  [ActionTypes.GET_SOLUTION_MY_PROGRAMS_V2, getSolutionMyProgramsV2],
  [ActionTypes.DO_SOLUTION_MY_SOLUTION_UPDATE_GOAL_V2, doSolutionMySolutionUpdateGoalV2],
  [ActionTypes.DO_MY_SOLUTION_REMOVE_V2, doMySolutionRemoveV2]
];

function* getSolutionMyGoals({ payload }) {
  yield put(Actions.loadingSolutionMyGoals());

  const response = yield call(loadSolutionMyGoals, payload);

  if (!response.successful) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    yield call(delay, 300);
    yield put(Actions.loadedSolutionMyGoals(response.data));
  }
}

function* getSolutionMyPrograms({ payload }) {
  yield put(Actions.loadingSolutionMyPrograms());

  const response = yield call(loadSolutionMyPrograms, payload);

  if (!response.successful) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    yield put(Actions.loadedSolutionMyPrograms(response.data));
  }
}

function* doSolutionMySolutionUpdateGoal({ payload }) {
  const response = yield call(loadSolutionMySolutionUpdateGoal, payload);

  if (!response.successful) {
    // Show the error message
    // Should hopefully never get to here with the checks in the frontend, use this as a failsafe
    const firstError = Object.keys(response.data);
    const message = "Please fill out the missing data";
    yield put(
      Actions.showToast({
        type: "error",
        message: `${firstError} - ${message}`,
      })
    );
    yield put(Actions.doneSolutionMySolutionUpdateGoalError());
  } else {
    yield put(Actions.doneSolutionMySolutionUpdateGoal(response.data));
    yield put(
      Actions.showToast({
        type: "success",
        message: "Successfully updated your Goal!",
      })
    );
  }
}

function* getSolutionMyGoalsV2() {
  yield put(Actions.loadingSolutionMyGoalsV2());

  const response = yield call(loadSolutionMyGoalsV2);

  if (response.data?.detail) {
    // Show message here
    yield put(
      Actions.showToast({
        type: "error",
        message: `${response.data.detail}`,
      })
    );
  } else {
    yield put(Actions.loadedSolutionMyGoalsV2(response));
  }
}

function* getSolutionMyProgramsV2() {
  yield put(Actions.loadingSolutionMyProgramsV2());

  const savedPrograms = yield call(loadMySavedProgramsV2);
  const activePrograms = yield call(loadMyActiveProgramsV2);
  const archivedPrograms = yield call(loadMyArchivedProgramsV2);

  if (savedPrograms.data?.detail) {
    yield put(
      Actions.showToast({
        type: "error",
        message: `${response.data.detail}`,
      })
    );
  } else {
    yield put(Actions.loadedSolutionMyProgramsV2({
      non_active: savedPrograms,
      active: activePrograms,
      archived: archivedPrograms
    }));
  }
}

function* doSolutionMySolutionUpdateGoalV2({ payload }) {
  const response = yield call(loadSolutionMySolutionUpdateGoalV2, payload);

  if (!response.successful) {
    // Show the error message
    // Should hopefully never get to here with the checks in the frontend, use this as a failsafe
    const firstError = Object.keys(response.data);
    const message = "Please fill out the missing data";
    yield put(
      Actions.showToast({
        type: "error",
        message: `${firstError} - ${message}`,
      })
    );
    yield put(Actions.doneSolutionMySolutionUpdateGoalErrorV2());
  } else {
    yield put(Actions.doneSolutionMySolutionUpdateGoalV2(response.data));
    yield put(
      Actions.showToast({
        type: "success",
        message: "Successfully updated your Goal!",
      })
    );
  }
}

function* doMySolutionRemoveV2({ payload }) {
  const response = yield call(removeSavedSolutionV2, payload.id);

  if (response.data?.detail) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    yield put(
      Actions.doneMySolutionRemoveV2(payload.id)
    );
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Program removed",
      })
    );
  }
}
